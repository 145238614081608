<template>
  <b-table-simple
    responsive
    hover
    class="compact-table"
  >
    <b-tbody>
      <b-tr>
        <b-th>
          Invoice No.
        </b-th>
        <b-td>
          {{ item.invoice_no }}
        </b-td>
        <b-th>
          Date
        </b-th>
        <b-td>
          {{ formatedDate(item.date) }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>
          Amount
        </b-th>
        <b-td>
          {{ formateNumber(item.amount) }}
        </b-td>
        <b-th>
          Total Items
        </b-th>
        <b-td>
          {{ item.total_items }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>
          Company Group
        </b-th>
        <b-td>
          {{ item.company_group__name }}
        </b-td>
        <b-th>
          Supplier
        </b-th>
        <b-td>
          {{ item.supplier__name }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>
          PDF File
        </b-th>
        <b-td>
          <a
            v-if="item.pdf_file"
            :href="item.pdf_file"
            target="_blank"
            class="my-50"
          >
            <span>View File</span>
          </a>
          <span v-else>-</span>
        </b-td>
        <b-th>
          Created By
        </b-th>
        <b-td>
          {{ item.created_by__fullname }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-th> Verified PDF </b-th>
        <b-td colspan="3">
          <a
            v-for="(file, index) in item.verified_pdf"
            :key="'verified-file-' + index"
            :href="file.verified_pdf"
            target="_blank"
          >
            File {{ index + 1 }}
            <span v-if="index < item.verified_pdf.length - 1">, </span>
          </a>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>

import {
  BTableSimple, BTbody, BTd, BTr, BTh,
} from 'bootstrap-vue'

import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTd,
    BTr,
    BTh,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>
