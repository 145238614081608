<template>
  <b-card no-body>
    <b-card-header>
      <h4>Invoices</h4>
    </b-card-header>

    <b-card-body>
      <invoice-filters
        @filtersUpdated="filtersChangeHandler"
      />

      <invoice-list
        :filters="filters"
        @edit-invoice="editInvoice"
        @update-invoice-status="updateInvoiceStatus"
      />

      <invoice-form
        v-if="editInvoiceDetails"
        :bilty-id="editInvoiceDetails.biltyId"
        :invoice-id="editInvoiceDetails.invoiceId"
        @modal-closed="closeInvoiceForm"
        @saved="refreshInvoiceList"
      />

      <invoice-status-form
        v-if="updateInvoiceStatusDetails"
        :invoice-id="updateInvoiceStatusDetails.invoiceId"
        @modal-closed="closeInvoiceStatusForm"
        @saved="refreshInvoiceList"
      />

    </b-card-body>
  </b-card>
</template>

<script>
import {
  VBTooltip, BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'

import bus from '@/bus'
import dateMixin from '@/mixins/dateMixin'
import InvoiceFilters from '@/components/Invoices/Invoice/InvoiceFilters.vue'
import InvoiceList from '@/components/Invoices/Invoice/InvoiceList.vue'
import InvoiceForm from '@/components/Invoices/Invoice/InvoiceForm.vue'
import InvoiceStatusForm from '@/components/Invoices/Invoice/InvoiceStatusForm.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    InvoiceFilters,
    InvoiceList,
    InvoiceForm,
    InvoiceStatusForm,
  },
  mixins: [dateMixin],
  data() {
    return {
      editInvoiceDetails: null,
      updateInvoiceStatusDetails: null,
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
    editInvoice({ invoiceId, biltyId }) {
      this.editInvoiceDetails = {
        invoiceId,
        biltyId,
      }
    },
    updateInvoiceStatus({ invoiceId }) {
      this.updateInvoiceStatusDetails = {
        invoiceId,
      }
    },
    closeInvoiceForm() {
      this.displayBiltyDetailsId = this.editInvoiceDetails.biltyId
      this.editInvoiceDetails = null
    },
    closeInvoiceStatusForm() {
      this.updateInvoiceStatusDetails = null
    },
    refreshInvoiceList() {
      bus.$emit('invoices/invoiceList/refresh')
    },
  },
}
</script>
