var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":((_vm.invoiceId ? 'Edit' : 'Add') + " Invoice"),"size":"lg","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('modal-closed')},"ok":_vm.submit},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.saving || _vm.loading || _vm.loadingError ? true : false},on:{"click":function($event){return ok()}}},[_vm._v(" Submit "),(_vm.saving)?_c('b-spinner',{attrs:{"small":"","label":"Small Spinner"}}):_vm._e()],1)]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Small Spinner","variant":"primary"}})],1):_vm._e(),_c('b-alert',{attrs:{"variant":"danger","show":_vm.loadingError ? true : false}},[_c('div',{staticClass:"alert-body"},[_c('p',[_vm._v(" "+_vm._s(_vm.loadingError)+" ")])])]),(!_vm.loading && !_vm.loadingError)?_c('div',[_c('h6',[_vm._v("Bilty Details")]),_c('bilty-info',{attrs:{"item":_vm.biltyDetails}}),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-observer',{ref:"invoiceForm"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Invoice No.","vid":"invoice_no","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Invoice No."}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.invoiceNumber),callback:function ($$v) {_vm.invoiceNumber=$$v},expression:"invoiceNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1771883957)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Date"}},[_c('validation-provider',{attrs:{"name":"Date","vid":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"menu-class":"w-100","calendar-width":"100%","state":errors.length > 0 ? false:null},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3596700852)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Amount","vid":"amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Amount"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","min":"0","step":"0.01"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1865015779)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Total Items","vid":"total_items","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Total Items"}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.totalItems),callback:function ($$v) {_vm.totalItems=$$v},expression:"totalItems"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,656053300)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Company Group","vid":"company_group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Group","state":errors.length > 0 ? false : null}},[_c('custom-v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.companyGroupOptions,"label":"text","placeholder":"Select Company Group","state":errors.length > 0 ? false:null,"reduce":function (option) { return option.id; }},model:{value:(_vm.companyGroup),callback:function ($$v) {_vm.companyGroup=$$v},expression:"companyGroup"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1467855174)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Supplier","vid":"supplier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Supplier","state":errors.length > 0 ? false : null}},[_c('custom-v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.supplierOptions,"label":"text","placeholder":"Select Supplier","reduce":function (option) { return option.id; }},model:{value:(_vm.supplier),callback:function ($$v) {_vm.supplier=$$v},expression:"supplier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1505022771)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"PDF File","vid":"pdf_file","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"PDF File"}},[_c('b-form-file',{attrs:{"state":errors.length > 0 ? false:null,"accept":".pdf"},model:{value:(_vm.pdfFile),callback:function ($$v) {_vm.pdfFile=$$v},expression:"pdfFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1096149283)}),(_vm.savedPDFFile)?_c('div',[_c('a',{staticClass:"my-50",attrs:{"href":_vm.savedPDFFile,"target":"_blank"}},[_c('span',[_vm._v("View File")])]),_c('b-form-checkbox',{staticClass:"my-50",attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.clearPDF),callback:function ($$v) {_vm.clearPDF=$$v},expression:"clearPDF"}},[_vm._v(" Remove File ")])],1):_vm._e()],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }