<template>
  <b-modal
    v-model="showModal"
    title="Update Invoice Status"
    size="lg"
    no-close-on-backdrop
    @hidden="$emit('modal-closed')"
    @ok="submit"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">
      <invoice-info
        :item="invoiceDetails"
      />

      <b-form
        @submit.prevent="submit"
      >
        <b-table-simple />

        <b-table-simple
          responsive
          hover
          class="compact-table"
        >
          <b-thead>
            <b-tr>
              <b-th>
                Stock Recieved
              </b-th>
              <b-th>
                PURCHASE ENTRY
              </b-th>
              <b-th>
                STOCK CHECK
              </b-th>
              <b-th>
                STOCK RACKED
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>
                <b-form-checkbox
                  v-model="stockRecieved"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="true"
                />
              </b-td>
              <b-td>
                <b-form-checkbox
                  v-model="purchaseEntry"
                  :value="true"
                  :unchecked-value="false"
                />
              </b-td>
              <b-td>
                <b-form-checkbox
                  v-model="stockCheck"
                  :value="true"
                  :unchecked-value="false"
                />
              </b-td>
              <b-td>
                <b-form-checkbox
                  v-model="stockRacked"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="!stage2enabled"
                />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="saving || loading || loadingError ? true : false"
        @click="ok()"
      >
        Submit
        <b-spinner
          v-if="saving"
          small
          label="Small Spinner"
        />
      </b-button>

    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BForm, BSpinner, BAlert, BFormCheckbox, BTableSimple, BTr, BTh, BTd, BThead, BTbody,
} from 'bootstrap-vue'

import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dateMixin from '@/mixins/dateMixin'

import InvoiceInfo from '@/components/Invoices/Invoice/InvoiceInfo.vue'

export default {
  components: {
    BModal,
    BForm,
    BButton,
    BSpinner,
    BAlert,
    BFormCheckbox,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BThead,
    BTbody,
    InvoiceInfo,
  },
  mixins: [dateMixin],
  props: {
    invoiceId: {
      type: Number,
      required: false,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      showModal: true,
      invoiceDetails: null,
      stockRecieved: null,
      purchaseEntry: null,
      stockCheck: null,
      stockRacked: null,
      loading: false,
      loadingError: null,
      saving: false,
    }
  },
  computed: {
    stage2enabled() {
      return this.purchaseEntry && this.stockCheck
    },
  },
  watch: {
    stage2enabled(val) {
      if (val === false) {
        this.stockRacked = false
      }
    },
  },
  created() {
    this.initializeForm()
  },
  methods: {
    async initializeForm() {
      this.loading = true
      if (this.invoiceId) {
        try {
          const res = await axios.get(`/invoices/invoice/${this.invoiceId}/`)
          this.invoiceDetails = res.data
          this.stockRecieved = res.data.stock_recieved
          this.purchaseEntry = res.data.purchase_entry
          this.stockCheck = res.data.stock_check
          this.stockRacked = res.data.stock_racked
        } catch (error) {
          this.loadingError = error?.response?.data?.detail || 'Error fetching invoice'
          this.loading = false
          return
        }
      }

      this.loading = false
    },
    async submit(event) {
      event.preventDefault()

      this.saving = true

      const formData = new FormData()
      formData.append('purchase_entry', this.purchaseEntry)
      formData.append('stock_check', this.stockCheck)
      formData.append('stock_racked', this.stockRacked)

      axios.patch(`/invoices/invoice/${this.invoiceId}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invoice Status updated successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        this.saving = false
        this.showModal = false
        this.$emit('saved')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.detail || 'Error updating invoice status',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        this.saving = false
      })
    },
  },
}

</script>
