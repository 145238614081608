<template>
  <div>

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table"
      no-local-sorting
      show-empty
      empty-text="No records found"
      @sort-changed="sortingChanged"
    >
      <template #cell(amount)="data">
        {{ formateNumber(data.item.amount) }}
      </template>
      <template #cell(stock_recieved)="data">
        <status-icon
          hide-label
          :value="data.item.stock_recieved.toString()"
        />
      </template>

      <template #cell(purchase_entry)="data">
        <status-icon
          hide-label
          :value="data.item.purchase_entry.toString()"
        />
      </template>

      <template #cell(stock_check)="data">
        <status-icon
          hide-label
          :value="data.item.stock_check.toString()"
        />
      </template>

      <template #cell(stock_racked)="data">
        <status-icon
          hide-label
          :value="data.item.stock_racked.toString()"
        />
      </template>

      <template #cell(date)="data">
        <span v-if="data.item.date">
          {{ formatedDate(data.item.date) }}
        </span>
      </template>

      <template #cell(bilty__received_date)="data">
        <span v-if="data.item.bilty__received_date">
          {{ formatedDate(data.item.bilty__received_date) }}
        </span>
      </template>

      <template #cell(pdf_file)="data">
        <a
          v-if="data.item.pdf_file"
          :href="data.item.pdf_file"
          target="_blank"
        >
          <feather-icon
            v-b-tooltip.hover="{boundary:'window'}"
            title="View File"
            icon="FileIcon"
            class="cursor-pointer"
            color="green"
          />
        </a>
        <feather-icon
          v-else
          icon="FileIcon"
          color="red"
        />
      </template>

      <template #cell(actions)="data">
        <feather-icon
          v-if="!biltyId"
          v-b-tooltip.hover="{boundary:'window'}"
          title="View Details"
          icon="EyeIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="displayInvoiceDetailsId = data.item.id"
        />

        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="Edit Invoice"
          icon="EditIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="$emit('edit-invoice', {
            invoiceId: data.item.id,
            biltyId: data.item.bilty
          })"
        />

        <feather-icon
          v-if="!biltyId"
          v-b-tooltip.hover="{boundary:'window'}"
          title="Update Invoice Status"
          icon="CheckSquareIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="$emit('update-invoice-status', {
            invoiceId: data.item.id
          })"
        />

      </template>
      <template #cell(ticket_status)="data">
        <status-icon :value="data.item.ticket_status" />
      </template>
    </b-table>

    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => {currentPage = page}"
    />

    <invoice-details
      v-if="displayInvoiceDetailsId"
      :invoice-id="displayInvoiceDetailsId"
      @modal-closed="displayInvoiceDetailsId = null"
    />

  </div>
</template>

<script>
import {
  BSpinner, BTable, BAlert, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'

import bus from '@/bus'
import dateMixin from '@/mixins/dateMixin'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import InvoiceDetails from '@/components/Invoices/Invoice/InvoiceDetails.vue'
import StatusIcon from '@/components/UI/StatusIcon.vue'
import formatMixin from '@/mixins/formatMixin'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BAlert,
    DetailedPagination,
    InvoiceDetails,
    StatusIcon,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    biltyId: {
      type: Number,
      required: false,
      default() {
        return null
      },
    },
    filters: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      results: [],
      tableFields: [
        { key: 'invoice_no', label: 'INVOICE NO.', sortable: true },
        { key: 'date', label: 'DATE', sortable: true },
        { key: 'bilty__received_date', label: 'BILTY RECEIVED DATE', sortable: true },
        {
          key: 'amount', label: 'AMOUNT', sortable: true, class: 'text-right pr-2',
        },
        { key: 'total_items', label: 'TOTAL ITEMS', sortable: true },
        { key: 'company_group__name', label: 'COMPANY GROUP', sortable: true },
        { key: 'supplier__name', label: 'SUPPLIER NAME', sortable: true },
        { key: 'pdf_file', label: 'FILE', sortable: true },
        { key: 'stock_recieved', label: 'STOCK RECIEVED', sortable: true },
        { key: 'purchase_entry', label: 'PURCHASE ENTRY', sortable: true },
        { key: 'stock_check', label: 'STOCK CHECK', sortable: true },
        { key: 'stock_racked', label: 'STOCK RACKED', sortable: true },
        { key: 'ticket_count', label: 'Ticket Count', sortable: false },
        { key: 'ticket_status', label: 'Ticket Status', sortable: false },
        { key: 'actions', label: 'Actions' },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      sortBy: 'bilty__received_date',
      sortDesc: true,
      displayInvoiceDetailsId: null,
    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.fetchItems()
      }
    },
  },
  created() {
    bus.$on('invoices/invoiceList/refresh', this.fetchItems)
    this.fetchItems()
  },
  destroyed() {
    bus.$off('invoices/invoiceList/refresh', this.fetchItems)
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    fetchItems() {
      this.loading = true
      axios.post('/invoices/filtered_invoices', {
        page: this.currentPage,
        per_page: this.perPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        filters: { ...this.filters, bilty_id: this.biltyId },
      })
        .then(res => {
          this.results = res.data.results
          this.totalItems = res.data.count
          this.loadingError = null
          this.loading = false
        }).catch(error => {
          this.loading = false
          const errorResponse = error?.response
          if (errorResponse && errorResponse.status === 404 && this.currentPage > 1) {
            this.currentPage -= 1
            this.fetchItems()
          } else {
            this.loadingError = error?.response?.data?.detail || 'Error fetching invoice list'
          }
        })
    },
  },
}
</script>
