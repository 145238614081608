<template>
  <b-modal
    v-model="showModal"
    :title="`${invoiceId ? 'Edit' : 'Add'} Invoice`"
    size="lg"
    no-close-on-backdrop
    @hidden="$emit('modal-closed')"
    @ok="submit"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">
      <h6>Bilty Details</h6>
      <bilty-info
        :item="biltyDetails"
      />

      <b-form
        @submit.prevent="submit"
      >
        <validation-observer
          ref="invoiceForm"
        >
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Invoice No."
                vid="invoice_no"
                rules="required"
              >
                <b-form-group
                  label="Invoice No."
                >
                  <b-form-input
                    v-model="invoiceNumber"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Date"
                  vid="date"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="date"
                    menu-class="w-100"
                    calendar-width="100%"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Amount"
                vid="amount"
                rules="required"
              >
                <b-form-group
                  label="Amount"
                >
                  <b-form-input
                    v-model="amount"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                    min="0"
                    step="0.01"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Total Items"
                vid="total_items"
                rules="required"
              >
                <b-form-group label="Total Items">
                  <b-form-input
                    v-model="totalItems"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Company Group"
                vid="company_group"
                rules="required"
              >
                <b-form-group
                  label="Company Group"
                  :state="errors.length > 0 ? false : null"
                >
                  <custom-v-select
                    v-model="companyGroup"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="companyGroupOptions"
                    label="text"
                    placeholder="Select Company Group"
                    :state="errors.length > 0 ? false:null"
                    :reduce="option => option.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Supplier"
                vid="supplier"
                rules="required"
              >
                <b-form-group
                  label="Supplier"
                  :state="errors.length > 0 ? false : null"
                >
                  <custom-v-select
                    v-model="supplier"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="supplierOptions"
                    label="text"
                    placeholder="Select Supplier"
                    :reduce="option => option.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="PDF File"
                vid="pdf_file"
                rules=""
              >
                <b-form-group label="PDF File">
                  <b-form-file
                    v-model="pdfFile"
                    :state="errors.length > 0 ? false:null"
                    accept=".pdf"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <div v-if="savedPDFFile">
                <a
                  :href="savedPDFFile"
                  target="_blank"
                  class="my-50"
                >
                  <span>View File</span>
                </a>

                <b-form-checkbox
                  v-model="clearPDF"
                  class="my-50"
                  :value="true"
                  :unchecked-value="false"
                >
                  Remove File
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="saving || loading || loadingError ? true : false"
        @click="ok()"
      >
        Submit
        <b-spinner
          v-if="saving"
          small
          label="Small Spinner"
        />
      </b-button>

    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BButton, BFormInput, BFormDatepicker, BForm, BSpinner, BAlert, BFormFile, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dateMixin from '@/mixins/dateMixin'

import BiltyInfo from '../Bilty/BiltyInfo.vue'

export default {
  components: {
    CustomVSelect,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormDatepicker,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BFormFile,
    BFormCheckbox,
    BiltyInfo,
  },
  mixins: [dateMixin],
  props: {
    invoiceId: {
      type: Number,
      required: false,
      default() {
        return null
      },
    },
    biltyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      invoiceNumber: null,
      date: null,
      amount: null,
      totalItems: null,
      companyGroup: null,
      companyGroupOptions: [],
      supplier: null,
      supplierOptions: [],
      pdfFile: null,
      savedPDFFile: null,
      clearPDF: false,
      loading: false,
      loadingError: null,
      saving: false,
      biltyDetails: null,
    }
  },
  created() {
    this.initializeForm()
  },
  methods: {
    async initializeForm() {
      this.loading = true

      try {
        const res = await axios.get(`/invoices/bilty/${this.biltyId}/`)
        this.biltyDetails = res.data
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching bilty'
        this.loading = false
        return
      }

      try {
        let res
        res = await axios.get('/company_group_options')
        this.companyGroupOptions = res.data.data

        res = await axios.get('/supplier_options')
        this.supplierOptions = res.data.data
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching dropdown options'
        this.loading = false
        return
      }

      let invoice
      if (this.invoiceId) {
        try {
          const res = await axios.get(`/invoices/invoice/${this.invoiceId}/`)
          invoice = res.data
        } catch (error) {
          this.loadingError = error?.response?.data?.detail || 'Error fetching invoice'
          this.loading = false
          return
        }
      }

      if (this.invoiceId) {
        this.invoiceNumber = invoice.invoice_no
        this.date = invoice.date
        this.amount = invoice.amount
        this.totalItems = invoice.total_items
        this.companyGroup = invoice.company_group
        this.supplier = invoice.supplier
        this.pdfFile = null
        this.savedPDFFile = invoice.pdf_file
      } else {
        this.invoiceNumber = null
        this.date = null
        this.amount = null
        this.totalItems = null
        this.companyGroup = null
        this.supplier = null
        this.pdfFile = null
        this.savedPDFFile = null
      }

      this.loading = false
    },
    async submit(event) {
      event.preventDefault()

      const isFormValid = await this.$refs.invoiceForm.validate()
      if (!isFormValid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please correct the form errors',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.saving = true

      const formData = new FormData()
      formData.append('invoice_no', this.invoiceNumber)
      formData.append('date', this.date)
      formData.append('amount', this.amount)
      formData.append('total_items', this.totalItems)
      formData.append('company_group', this.companyGroup)
      formData.append('supplier', this.supplier)
      formData.append('bilty', this.biltyId)
      if (this.pdfFile) {
        formData.append('pdf_file', this.pdfFile)
      }
      if (this.savedPDFFile) {
        formData.append('clear_pdf', this.clearPDF)
      }

      let operation
      let message
      if (this.invoiceId) {
        operation = axios.patch(`/invoices/invoice/${this.invoiceId}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        message = 'Invoice updated successfully'
      } else {
        operation = axios.post('/invoices/invoice/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        message = 'Invoice created successfully'
      }

      operation
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$refs.invoiceForm.reset()
          this.saving = false
          this.showModal = false
          this.$emit('saved')
        }).catch(error => {
          this.$refs.invoiceForm.reset()

          const statusCode = error?.response?.status
          const serverErrors = error?.response?.data
          if (statusCode === 400 && serverErrors) {
            this.$refs.invoiceForm.setErrors(serverErrors)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please correct the form errors',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.response?.data?.detail || 'Error saving invoice',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          this.saving = false
        })
    },
  },
}

</script>
