<template>
  <b-row>
    <b-col md="3">
      <b-form-group
        label="Company Group"
      >
        <custom-v-select
          v-model="selectedCompanyGroups"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="companyGroupOptions"
          label="text"
          placeholder="Select Company Groups"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Supplier"
      >
        <custom-v-select
          v-model="selectedSuppliers"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="supplierOptions"
          label="text"
          placeholder="Select Suppliers"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Date Range"
      >
        <flat-picker
          v-model="dateRange"
          placeholder="Select Date Range"
          :config="{ mode: 'range'}"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Bilty Recieved Date Range"
      >
        <flat-picker
          v-model="biltyReceivedDateRange"
          placeholder="Select Date Range"
          :config="{ mode: 'range'}"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Invoice Number"
      >
        <b-form-input
          v-model="invoiceNumber"
          type="text"
          placeholder="Search Invoice Number"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Purchase Entry"
      >
        <custom-v-select
          v-model="selectedPurchaseEntry"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="statusFieldOptions"
          label="text"
          placeholder="Select Purchase Entry"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Stock Check"
      >
        <custom-v-select
          v-model="selectedStockCheck"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="statusFieldOptions"
          label="text"
          placeholder="Select Stock Check"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Stock Racked"
      >
        <custom-v-select
          v-model="selectedStockRacked"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="statusFieldOptions"
          label="text"
          placeholder="Select Stock Racked"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="File Availability"
      >
        <custom-v-select
          v-model="selectFileAvailability"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="fileAvailabilityOptions"
          label="text"
          placeholder="Select File Availability"
          :close-on-select="false"
          :deselect-from-dropdown="true"
          :reduce="data => data.value"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  VBTooltip, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    FlatPicker,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    CustomVSelect,
  },
  data() {
    return {
      companyGroupOptions: [],
      selectedCompanyGroups: [],
      supplierOptions: [],
      selectedSuppliers: [],
      dateRange: null,
      biltyReceivedDateRange: null,
      invoiceNumber: null,
      statusFieldOptions: [{ text: 'True', value: true }, { text: 'False', value: false }],
      fileAvailabilityOptions: [{ text: 'Available', value: true }, { text: 'Un-available', value: false }],
      selectedPurchaseEntry: [],
      selectedStockCheck: [],
      selectedStockRacked: [],
      selectFileAvailability: null,
    }
  },
  watch: {
    dateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
    biltyReceivedDateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/company_group_options')
        .then(res => {
          this.companyGroupOptions = res.data.data
        })

      axios.get('/supplier_options')
        .then(res => {
          this.supplierOptions = res.data.data
        })
    },
    filtersChangeHandler() {
      const filtersdata = {}
      if (this.selectedCompanyGroups.length > 0) {
        filtersdata.company_groups = this.selectedCompanyGroups.map(item => item.id)
      }
      if (this.selectedSuppliers.length > 0) {
        filtersdata.suppliers = this.selectedSuppliers.map(item => item.id)
      }
      if (this.dateRange) {
        const [dateFrom, dateTo] = this.dateRange.split(' to ')
        if (dateFrom && dateTo) {
          filtersdata.date_from = dateFrom
          filtersdata.date_to = dateTo
        }
      }
      if (this.biltyReceivedDateRange) {
        const [dateFrom, dateTo] = this.biltyReceivedDateRange.split(' to ')
        if (dateFrom && dateTo) {
          filtersdata.bilty_received_date_from = dateFrom
          filtersdata.bilty_received_date_to = dateTo
        }
      }
      if (this.closedOnDateRange) {
        const [closedOnDateFrom, closedOnDateTo] = this.closedOnDateRange.split(' to ')
        if (closedOnDateFrom && closedOnDateTo) {
          filtersdata.closed_on_from = closedOnDateFrom
          filtersdata.closed_on_to = closedOnDateTo
        }
      }
      if (this.selectedStatusOption) {
        filtersdata.status = this.selectedStatusOption.value
      }
      if (this.invoiceNumber) {
        filtersdata.invoice_no = this.invoiceNumber
      }
      if (this.selectedPurchaseEntry.length > 0) {
        filtersdata.purchase_entry = this.selectedPurchaseEntry.map(item => item.value)
      }
      if (this.selectedStockCheck.length > 0) {
        filtersdata.stock_check = this.selectedStockCheck.map(item => item.value)
      }
      if (this.selectedStockRacked.length > 0) {
        filtersdata.stock_racked = this.selectedStockRacked.map(item => item.value)
      }

      if (this.selectFileAvailability !== null) {
        filtersdata.is_file = this.selectFileAvailability
      }
      this.$emit('filtersUpdated', filtersdata)
    },
  },

}
</script>
