<template>
  <b-table-simple
    responsive
    hover
    class="compact-table"
  >
    <b-tbody>
      <b-tr>
        <b-th>
          Bilty No.
        </b-th>
        <b-td>
          {{ item.bilty_number }}
        </b-td>
        <b-th>
          Date
        </b-th>
        <b-td>
          {{ formatedDate(item.date) }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>
          Lot Size
        </b-th>
        <b-td>
          {{ item.lot_size }}
        </b-td>
        <b-th>
          Transporter
        </b-th>
        <b-td>
          {{ item.transporter__name }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>
          Received Date
        </b-th>
        <b-td>
          {{ formatedDate(item.received_date) }}
        </b-td>
        <b-th>
          Created By
        </b-th>
        <b-td>
          {{ item.created_by__fullname }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-th> Received By </b-th>
        <td>{{ item.received_by__fullname }}</td>
        <b-th> Cost per Carton </b-th>
        <td>{{ formateNumber(item.cost_per_carton) }}</td>
      </b-tr>
      <b-tr>
        <th>To Pay Charge</th>
        <td>
          {{ formateNumber(item.to_pay_charge) || "-" }}
        </td>
        <th>Cost</th>
        <td>
          {{
            (item.lot_size &&
              item.cost_per_carton &&
              formateNumber(item.lot_size * item.cost_per_carton)) ||
              "-"
          }}
        </td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BTbody, BTd, BTr, BTh,
} from 'bootstrap-vue'

import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTd,
    BTr,
    BTh,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>
